import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { RecordedWorkingHoursService } from 'src/app/applications/subscription/modules/recorded-working-hours/services/recorded-working-hours.service';
import { finalize, Subject } from 'rxjs';
import { LocalStorageService } from 'src/app/kernel/tools/services/local-storage.service';
import { ELocalizationLang } from 'src/app/vogo-core/enums';
import { AuthService } from 'src/app/vogo-core/services/auth.service';
import { LanguageService } from 'src/app/vogo-core/services/language.service';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements OnInit, OnDestroy {
  router = inject(Router);
  authService = inject(AuthService);
  langService = inject(LanguageService);
  localStorage = inject(LocalStorageService);
  currentLang = inject(LOCALE_ID) as ELocalizationLang;
  recordedWorkingHoursService = inject(RecordedWorkingHoursService);

  lang: ELocalizationLang = ELocalizationLang.EN;

  formattedTime: string = '';
  isTracking: boolean = false;
  private subscriptions = new Subscription();
  showMenu: boolean = false;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.lang = this.currentLang;
    if (this.lang === 'ar') this.document.body.setAttribute('dir', 'rtl');
  }

  @ViewChild('userMenu') userMenu!: ElementRef;
  @ViewChild('userMenuIcon') userMenuIcon!: ElementRef;

  ngOnInit() {
    this.recordedWorkingHoursService.initActiveWorkRecord();

    this.recordedWorkingHoursService.trackingState$.subscribe({
      next: (val) => {
        this.formattedTime = val.formattedTime;
        this.isTracking = val.isTracking;
      },
    })
    this.subscriptions.add(
    );
  }

  ngOnDestroy(): void {
    if(this.subscriptions){
      this.subscriptions.unsubscribe();
    }
  }


  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  logout() {
    this.authService.logout();
  }

  toggleLang() {
    this.lang =
      this.lang === ELocalizationLang.EN
        ? ELocalizationLang.AR
        : ELocalizationLang.EN;
    this.authService
      .setMyPreferedLang(this.lang)
      .pipe(
        finalize(() => {
          this.langService.changeLang(this.lang);
        })
      )
      .subscribe();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    const clickedInsideMenu = this.userMenu?.nativeElement.contains(
      event.target
    );
    const clickedToggleButton = this.userMenuIcon?.nativeElement.contains(
      event.target
    );
    if (!clickedInsideMenu && !clickedToggleButton && this.showMenu) {
      this.showMenu = false;
    }
  }
}
