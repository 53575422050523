import { Directive, Input } from '@angular/core';
import { ToggleSideNav } from './toggle-side-nav';
import { SideNavStatusEnum } from '../interfaces/side-nav-status.enum';

@Directive({
  selector: '[selectedItem]'
})
export class SelectedItemDirective extends ToggleSideNav {
  @Input('selectedItem') override sideNav?: HTMLDivElement | undefined;

  override calcSideNavStatus() {
    if(this.bodyWidth <= this.MOBILE_WIDTH) {
      this.status = SideNavStatusEnum.mouseenter;
      this.canHover = false;
    } else {
      this.status = SideNavStatusEnum.expanded
      this.canHover = false;
    }
  }

  protected override calcActionBtnPosition(): void {
    this.collabsedBtn.style.insetInlineStart = `${this.actionBarWidth + this.linksNavWidth}px`
  }
}
